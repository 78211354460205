import React from 'react';

import Home from '../components/Home';
import Layout from '../components/Layout';


export default ({ location }) => (
  <Layout location={location} language="en">
    <Home />
  </Layout>
);
